import { BarcodeScanner, PermissionStatus } from "@capacitor-mlkit/barcode-scanning"
import { Geolocation } from "@capacitor/geolocation"
import { useCallback, useEffect, useState } from "react"

export default function useScanPermissions() {
    const [locationGranted, setLocationGranted] = useState<boolean | null>(undefined)
    const [locationDenied, setLocationDenied] = useState<boolean | null>(undefined)
    const [scannerSupported, setScannerSupported] = useState<boolean>(undefined)
    const [scannerDenied, setScannerDenied] = useState<boolean | null>(undefined)
    const [scannerGranted, setScannerGranted] = useState<boolean | null>(undefined)

    const checkScanPermissions = useCallback(async (request = false) => {
        let permissionStatus: PermissionStatus

        try {
            permissionStatus = await BarcodeScanner.checkPermissions()
            setScannerSupported(true)
        } catch (error) {
            setScannerSupported(false)
            return
        }

        if (request) {
            if (["prompt", "prompt-with-rationale"].includes(permissionStatus.camera)) {
                permissionStatus = await BarcodeScanner.requestPermissions()
            }
        }

        if (["granted", "limited"].includes(permissionStatus.camera)) {
            setScannerGranted(true)
            setScannerDenied(false)
            return true
        } else if (permissionStatus.camera === "denied") {
            setScannerGranted(false)
            setScannerDenied(true)
            console.error("User denied camera permissions.", permissionStatus.camera)
            return false
        } else {
            setScannerGranted(null)
            setScannerDenied(null)
            return null
        }
    }, [])

    const checkLocationPermissions = useCallback(async (request = false) => {
        let permissionStatus = await Geolocation.checkPermissions()

        if (request) {
            if (["prompt", "prompt-with-rationale"].includes(permissionStatus.location)) {
                permissionStatus = await Geolocation.requestPermissions({ permissions: ["location"] })
            }
        }

        if (permissionStatus.location === "granted") {
            setLocationGranted(true)
            setLocationDenied(false)
            return true
        } else if (permissionStatus.location === "denied") {
            setLocationGranted(false)
            setLocationDenied(true)

            console.error("User denied location permissions.", permissionStatus.location)

            return false
        } else {
            setLocationGranted(null)
            setLocationDenied(null)
            return null
        }
    }, [])

    useEffect(() => {
        // Do an initial permissions check
        checkLocationPermissions()
        checkScanPermissions()
    }, [checkLocationPermissions, checkScanPermissions])

    return {
        locationGranted,
        locationDenied,
        scannerSupported,
        scannerGranted,
        scannerDenied,
        checkScanPermissions,
        checkLocationPermissions,
    }
}
