import { faMagnifyingGlass, faSliders } from "@fortawesome/pro-regular-svg-icons"
import { faDoorOpen, faHeart } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import clsx from "clsx"
import Fuse from "fuse.js"
import Image from "next/image"
import { useEffect, useRef, useState } from "react"
import Checkbox from "rsuite/Checkbox"
import CheckboxGroup from "rsuite/CheckboxGroup"
import Drawer from "rsuite/Drawer"

import { AMENITIES, CUISINES, PRICE_LEVELS } from "../../../constants/choices"
import useJune from "../../../hooks/useJune"
import { choicesToOptions } from "../../../utils/adapters"
import { cleanFilterDict } from "../../../utils/cleaners"
import Accordion from "../../accordions/Accordion/Accordion"
import { AccordionIndexedRef } from "../../accordions/Accordion/Accordion.types"
import Button from "../../buttons/Button/Button"
import Switch from "../../controls/Switch/Switch"
import styles from "./FilterDrawer.module.scss"
import { FilterDrawerProps, FilterFormCheckboxesRowProps, FilterFormToggleRowProps } from "./FilterDrawer.types"

export function FilterFormToggleRow(props: FilterFormToggleRowProps) {
    const { icon, title, subtitle, value, onToggle, borderPlacement = "bottom", padded, disabled = false } = props

    return (
        <div className={clsx(styles.formRow, { [styles.formRowPadded]: padded })}>
            <div className={styles.formRowIcon}>{icon}</div>
            <div className={clsx(styles.formRowField, { [styles.borderTop]: borderPlacement === "top" })}>
                <div className={styles.fieldDetails}>
                    <div className={styles.fieldTitle}>{title}</div>
                    <div className={styles.fieldSubtitle}>{subtitle}</div>
                </div>
                <div className={styles.fieldSwitch}>
                    <Switch onToggle={onToggle} checked={value} disabled={disabled} />
                </div>
            </div>
        </div>
    )
}

function FilterFormCheckboxesRow(props: FilterFormCheckboxesRowProps) {
    const { title, count, name, value, options, onChange, searchable = false, accordionRefs, accordionIndex } = props

    const [searchString, setSearchString] = useState("")

    const fuse = new Fuse(options, {
        shouldSort: true,
        findAllMatches: true,
        minMatchCharLength: 2,
        threshold: 0.1,
        keys: ["label"],
    })

    return (
        <div className={styles.formRow}>
            <div className={styles.formRowIcon}></div>
            <div className={styles.formRowField}>
                <Accordion
                    ref={(ref) => accordionRefs.current.push({ ref, index: accordionIndex })}
                    headerContent={
                        <div className={styles.fieldDetails}>
                            <div className={styles.fieldTitle}>
                                {title}
                                {count !== 0 && <span className={styles.fieldCount}>({count})</span>}
                            </div>
                        </div>
                    }
                    bodyContent={
                        <>
                            {searchable && (
                                <div className={styles.fieldSearch}>
                                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                                    <input
                                        name={`${name}_option_search`}
                                        value={searchString}
                                        onChange={(event) => setSearchString(event.target.value)}
                                        placeholder={`Search ${title}`}
                                    />
                                </div>
                            )}
                            <CheckboxGroup name={name} value={value} onChange={onChange}>
                                {searchString !== ""
                                    ? fuse.search(searchString).map((result) => (
                                          <Checkbox key={result.item.value} value={result.item.value}>
                                              {result.item.label}
                                          </Checkbox>
                                      ))
                                    : options.map((option) => (
                                          <Checkbox key={option.value} value={option.value}>
                                              {option.label}
                                          </Checkbox>
                                      ))}
                            </CheckboxGroup>
                        </>
                    }
                    onOpen={() =>
                        accordionRefs.current
                            .filter((obj) => obj.index !== accordionIndex)
                            .map((obj) => obj.ref?.close())
                    }
                />
            </div>
        </div>
    )
}

export default function FilterDrawer(props: FilterDrawerProps) {
    const { setIsFiltering, filters, setFilters, filterCount } = props
    const analytics = useJune()

    const cleanFilters = cleanFilterDict(filters)

    const [isPro, setIsPro] = useState(cleanFilters.isPro)
    const [isFavorite, setIsFavorite] = useState(cleanFilters.isFavorite)
    const [isOpen, setIsOpen] = useState(cleanFilters.isOpen)
    const [priceLevels, setPriceLevels] = useState(cleanFilters.priceLevels)
    const [cuisines, setCuisines] = useState(cleanFilters.cuisines)
    const [diets, setDiets] = useState(cleanFilters.diets)
    const [amenities, setAmenities] = useState(cleanFilters.amenities)

    const [nextFilterCount, setNextFilterCount] = useState(filterCount)

    const accordionRefs = useRef<AccordionIndexedRef[]>([])

    const applyFilters = () => {
        const appliedFilters = {
            is_pro: isPro ? [isPro] : undefined,
            is_favorite: isFavorite ? [isFavorite] : undefined,
            is_open: isOpen ? [isOpen] : undefined,
            price_level: Array.from(priceLevels),
            cuisines: Array.from(cuisines),
            diets: Array.from(diets),
            amenities: Array.from(amenities),
        }
        setFilters(appliedFilters)
        analytics?.track("Apply Filters", appliedFilters)
        setIsFiltering(false)
    }

    const clearFilters = () => {
        setIsPro(false)
        setIsFavorite(false)
        setIsOpen(false)
        setPriceLevels(new Set())
        setCuisines(new Set())
        setDiets(new Set())
        setAmenities(new Set())
    }

    useEffect(() => {
        let total = 0

        total += isPro ? 1 : 0
        total += isFavorite ? 1 : 0
        total += isOpen ? 1 : 0
        total += priceLevels.size
        total += cuisines.size
        total += diets.size
        total += amenities.size

        setNextFilterCount(total)
    }, [isPro, isFavorite, isOpen, priceLevels.size, cuisines.size, diets.size, amenities.size])

    return (
        <Drawer id="filterDrawer" size="full" placement="bottom" open={true} onClose={() => setIsFiltering(false)}>
            <Drawer.Header>
                <Drawer.Title>
                    <div className={styles.headerDisplay}>
                        <div className={styles.headerIcon}>
                            <FontAwesomeIcon icon={faSliders} />
                        </div>
                        <div className={styles.headerDetails}>
                            <div className={styles.headerTitle}>Filters</div>
                        </div>
                    </div>
                </Drawer.Title>
            </Drawer.Header>
            <Drawer.Body>
                <div className={styles.body}>
                    <div className={styles.form}>
                        <FilterFormToggleRow
                            icon={
                                <Image
                                    src="/icon_pro_filled.svg"
                                    priority={true}
                                    width={16}
                                    height={13}
                                    alt="Crown Filled"
                                />
                            }
                            title="Pro"
                            subtitle="Show restaurants that offer pro rewards"
                            value={isPro}
                            onToggle={() => setIsPro((value) => !value)}
                        />
                        <FilterFormToggleRow
                            icon={<FontAwesomeIcon icon={faHeart} />}
                            title="Favorites"
                            subtitle="Show restaurants you have favorited"
                            value={isFavorite}
                            onToggle={() => setIsFavorite((value) => !value)}
                        />
                        <FilterFormToggleRow
                            icon={<FontAwesomeIcon icon={faDoorOpen} />}
                            title="Open"
                            subtitle="Show restaurants that are currently open"
                            value={isOpen}
                            onToggle={() => setIsOpen((value) => !value)}
                        />
                        <FilterFormCheckboxesRow
                            title="Cuisines"
                            count={cuisines.size}
                            name="cuisines"
                            value={Array.from(cuisines)}
                            options={choicesToOptions(CUISINES, true)}
                            onChange={(value) => setCuisines(new Set(value))}
                            searchable={true}
                            accordionRefs={accordionRefs}
                            accordionIndex={0}
                        />
                        <FilterFormCheckboxesRow
                            title="Cost"
                            count={priceLevels.size}
                            name="price_level"
                            value={Array.from(priceLevels)}
                            options={choicesToOptions(PRICE_LEVELS)}
                            onChange={(value) => setPriceLevels(new Set(value))}
                            accordionRefs={accordionRefs}
                            accordionIndex={1}
                        />
                        <FilterFormCheckboxesRow
                            title="Amenities"
                            count={amenities.size}
                            name="amenities"
                            value={Array.from(amenities)}
                            options={choicesToOptions(AMENITIES)}
                            onChange={(value) => setAmenities(new Set(value))}
                            searchable={true}
                            accordionRefs={accordionRefs}
                            accordionIndex={2}
                        />
                    </div>
                    <div className={styles.buttons}>
                        <Button
                            variant="text"
                            content="Clear All"
                            clickHandler={() => clearFilters()}
                            extraClasses={[styles.drawerButton, styles.drawerButtonSecondary]}
                        />
                        <Button
                            variant="primary"
                            content={`Apply${nextFilterCount !== 0 ? ` (${nextFilterCount})` : ""}`}
                            clickHandler={() => applyFilters()}
                            extraClasses={[styles.drawerButton, styles.drawerButtonPrimary]}
                        />
                    </div>
                </div>
            </Drawer.Body>
        </Drawer>
    )
}
