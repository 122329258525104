import * as RadixSwitch from "@radix-ui/react-switch"

import styles from "./Switch.module.scss"
import { SwitchProps } from "./Switch.types"

export default function Switch(props: SwitchProps) {
    const { checked, onToggle, disabled = false } = props

    return (
        <RadixSwitch.Root className={styles.switch} checked={checked} onCheckedChange={onToggle} disabled={disabled}>
            <RadixSwitch.Thumb className={styles.switchThumb} />
        </RadixSwitch.Root>
    )
}
